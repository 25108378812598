import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import seapig, { REQUIREDS } from 'seapig';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from 'lodash';

import Layout from '../components/layout';
import { PageTitle } from '../components/title';

const Features = ({allFeature: { edges }}) => {
  const chunked = _.chunk(edges, 2);
  return (
    <>
      {chunked.map((pair, idx) => {

        const f1 = pair[0].node;
        const f2 = pair[1] && pair[1].node;

        return (
          <FeatureRow key={idx}>
            <FeatureCell feature {...f1} />
            { f2 && <FeatureCell feature {...f2} /> }
          </FeatureRow>
        );
      })}
    </>
  );
}

const FeatureCell = ({path, headline: { heading, facets, icon: { publicURL } }}) =>
  <div className="mt4">
    <div className="flex flex-row items-center">
      <div className="flex flex-column w-25">
        <Link to={path} className="grow dib">
          <img className="mb0" src={publicURL}/>
        </Link>
      </div>
      <div className="flex flex-column ml3">
        <Link className="link near-black hover-mid-gray grow dib" to={path}>
          <h3 className="mb0">{heading}</h3>
        </Link>
      </div>
    </div>
    <div className="mt4">
      <ul className="fa-ul">
        {facets.map((child, idx) => (<li key={idx}><FontAwesomeIcon icon={['fad', 'check']} listItem/>{child}</li>))}
      </ul>
    </div>
  </div>

const FeatureRow = ({children}) => {
  const {
    featureChildren,
  } = seapig(children, {
    feature: REQUIREDS,
  });

  const f1 = featureChildren[0];
  const f2 = featureChildren[1];

  return (
    <div className="row">
      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>

      <div className="col-xs-10 col-sm-5 col-md-2 col-lg-4">
        {f1}
      </div>

      <div className="col-xs-1 col-sm-0 col-md-0 col-lg-0"></div>
      <div className="col-xs-1 col-sm-0 col-md-0 col-lg-0"></div>

      {f2 &&
        <div className="col-xs-10 col-sm-5 col-md-4 col-lg-4">
          {f2}
        </div>
      }
    </div>
  );
}

const FeaturesPage = ({data}) =>
  <Layout>

    <div className="pb4-ns">

      <Helmet title="Features | SwitchSmith">
        <link rel="canonical" href="https://switchsmith.com" />
      </Helmet>

      <PageTitle title='Features'/>

      <div className="mt-ns">
        <Features {...data} />
      </div>

    </div>
  </Layout>

export default FeaturesPage;

export const query = graphql`
  query ApiIndexQuery {
    allFeature(sort: {fields: importance, order: DESC}) {
      totalCount
      edges {
        node {
          id
          area
          path
          importance
          headline {
            heading
            icon {
              publicURL
            }
            facets
          }
        }
      }
    }
  }
`